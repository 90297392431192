// DateUtils.ts

import _dayjs from 'dayjs'
// import * as isLeapYear from 'dayjs/plugin/isLeapYear' // import plugin
import advancedFormat from 'dayjs/plugin/advancedFormat' // import plugin
import isBetween from 'dayjs/plugin/isBetween' // import plugin
import relativeTime from 'dayjs/plugin/relativeTime' // import plugin
import isoWeek from 'dayjs/plugin/isoWeek' // import plugin
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear' // import plugin
import 'dayjs/locale/nl' // import locale

const dayjs = _dayjs // work around for typescript issue

// dayjs.extend(isLeapYear) // use plugin
dayjs.extend(isBetween) // use plugin
dayjs.extend(advancedFormat) // use plugin
dayjs.extend(relativeTime) // use plugin
dayjs.extend(isoWeeksInYear) // use plugin
dayjs.extend(isoWeek) // use plugin
dayjs.locale('nl') // use locale

export default {
    // Default a date using Day.js 'DD-MM-YYYY'
    getDate(date: number | string | Date): string {
        return dayjs(date).format('DD-MM-YYYY')
    },

    // Default a date using Day.js 'DD-MM-YYYY HH:ss'
    getDateTime(date: string | number): string {
        return dayjs(date).format('DD-MM-YYYY HH:mm')
    },

    timestampToDate(datetime: number): string {
        return dayjs(datetime).format('YYYY-MM-DD')
    },
    timestampToNextDay(datetime: number): string {
        return dayjs(datetime).add(1, 'day').format('YYYY-MM-DD')
    },
    timestampToDateTime(datetime: number): string {
        return dayjs(datetime).format('YYYY-MM-DD HH:mm')
    },

    dateToTimestamp(datetime: string | Date): number {
        return dayjs(datetime).valueOf()
    },

    // get a range of two dates. Show one if the start and end are the same 'DD-MM-YYYY'
    getDateRange(startDate: number | string | null | undefined, endDate: number | string | null | undefined): string {
        if (!startDate && !endDate) return ''
        if (!startDate || typeof startDate === 'undefined') {
            return '&#10132; ' + dayjs(endDate).format('DD-MM-YYYY')
        }
        if (!endDate || typeof endDate === 'undefined') {
            return dayjs(startDate).format('DD-MM-YYYY')
        }
        if (startDate === endDate) {
            return dayjs(startDate).format('DD-MM-YYYY')
        }
        return `${dayjs(startDate).format('DD-MM-YYYY')} / ${dayjs(endDate).format('DD-MM-YYYY')}`
    },

    // Format a date using Day.js
    formatDate(date: string | number | undefined, format: string = 'YYYY-MM-DD'): string {
        return dayjs(date).format(format)
    },
    // Parse a date string into a Day.js object
    parseDate(dateString: string, format: string = 'YYYY-MM-DD') {
        return dayjs(dateString, { format })
    },
    dateToTimestampAtNoon(datetime: string | Date): number {
        return dayjs(datetime).hour(12).minute(0).second(0).millisecond(0).valueOf() // Ensuring the time is set to noon
    },
    // Add days to a date
    addDays(date: string, daysToAdd: number) {
        return dayjs(date).add(daysToAdd, 'days')
    },

    // Subtract days from a date
    subtractDays(date: string, daysToSubtract: number) {
        return dayjs(date).subtract(daysToSubtract, 'days')
    },

    // Check if a date is before another date
    isBefore(dateA: string | number, dateB: string | number): boolean {
        return dayjs(dateA).isBefore(dateB)
    },

    // Check if a date is after another date
    isAfter(dateA: string | number, dateB: string | number): boolean {
        return dayjs(dateA).isAfter(dateB)
    },

    difference(dateA: string | number | Date, dateB: string | number | Date, unit: any = 'milisecond'): number {
        return dayjs(dateA).diff(dateB, unit)
    },

    relativeTime(date: string | number | Date): string {
        return dayjs(date).fromNow()
    },

    dateInRange(startDate: string, endDate: string, dateToCheck: string): boolean {
        const start = dayjs(startDate)
        const end = dayjs(endDate)
        const checkDate = dayjs(dateToCheck)

        return checkDate.isBetween(start, end, null, '[]') // '[]' includes start and end dates
    },

    getCurrentWeek(): number {
        const date = dayjs()
        return date.isoWeek()
    },

    getCurrentMonth(): string {
        return dayjs().format('MMMM')
    },

    getCurrentMonthInNumber(): number {
        const date = dayjs()
        return date.month() + 1
    },

    getPreviousMonthInNumber(): number {
        const currentDate = dayjs()
        const currentMonth = currentDate.month() + 1
        const previousMonthDate = currentDate.subtract(1, 'month')
        const previousMonth = previousMonthDate.month() + 1
        if (currentMonth === 1) {
            return 12
        }
        return previousMonth
    },

    getCurrentYear(): number {
        const date = dayjs()
        return date.year()
    },

    getPreviousWeek(): number {
        const currentDate = dayjs()
        const currentWeek = currentDate.isoWeek()

        const previousWeekDate = currentDate.subtract(1, 'week')
        const previousWeek = previousWeekDate.isoWeek()

        if (currentWeek === 1) {
            const previousYearWeeks = currentDate.subtract(1, 'year').isoWeeksInYear()
            return previousYearWeeks
        }

        return previousWeek
    },

    getPreviousMonth(): string {
        const previousMonthDate = dayjs().subtract(1, 'month')
        return previousMonthDate.format('MMMM')
    },

    getPreviousYear(): number {
        return dayjs().subtract(1, 'year').year()
    },

    // getDataInWeek(timestamp: number) {
    //     const startOfWeek = dayjs(timestamp).startOf('isoWeek').valueOf();
    //     const endOfWeek = dayjs(timestamp).endOf('isoWeek').valueOf();

    //     return {
    //         start: startOfWeek,
    //         end: endOfWeek,
    //     }
    // },

    getDataInWeek(weekNumber: number, year: number) {
        const firstDayOfYear = dayjs().year(year).startOf('year')
        const startOfWeek = firstDayOfYear.isoWeek(weekNumber).startOf('isoWeek').valueOf()
        const endOfWeek = firstDayOfYear.isoWeek(weekNumber).endOf('isoWeek').valueOf()
        return {
            start: startOfWeek,
            end: endOfWeek,
        }
    },

    getDataInMonth(monthNumber: number, year: number) {
        if (monthNumber < 1 || monthNumber > 12) {
            throw new Error('Invalid month number. It should be between 1 and 12.')
        }
        const startOfMonth = dayjs()
            .year(year)
            .month(monthNumber - 1)
            .startOf('month')
            .valueOf()
        const endOfMonth = dayjs()
            .year(year)
            .month(monthNumber - 1)
            .endOf('month')
            .valueOf()
        return {
            start: startOfMonth,
            end: endOfMonth,
        }
    },

    getDataInYear(year: number) {
        const startOfYear = dayjs().year(year).startOf('year').valueOf()
        const endOfYear = dayjs().year(year).endOf('year').valueOf()
        return {
            start: startOfYear,
            end: endOfYear,
        }
    },

    // getDataInWeek(timestamp: number): number {
    //     return dayjs(timestamp).isoWeek()
    // },
    // getDataInMonth(timestamp: number): number {
    //     return dayjs(timestamp).month()
    // },
    // getDataInYear(timestamp: number): number {
    //     return dayjs(timestamp).year()
    // },

    weekLastYear(dateTime: number): { halfWeekBefore: number; halfWeekAfter: number } {
        const oneYearInMilliseconds = 365 * 24 * 60 * 60 * 1000
        const fourDaysInMilliseconds = 4 * 24 * 60 * 60 * 1000

        // Calculate the timestamp for the same date last year
        const lastYearTimestamp = dateTime - oneYearInMilliseconds

        // Calculate half a week before and after
        const halfWeekBefore = lastYearTimestamp - fourDaysInMilliseconds
        const halfWeekAfter = lastYearTimestamp + fourDaysInMilliseconds

        // Return the date range in milliseconds
        return { halfWeekBefore, halfWeekAfter }
    },

    // Get the current date and time
    handleDateTime() {
        return dayjs()
    },

    _dayjs(value: any) {
        return dayjs(value || null)
    },

    now() {
        return dayjs().valueOf()
    },
}
