import { useLocalStorage } from '@vueuse/core'
import { getCalendars } from '@api/calendar'
import { apiGetUsers } from '@api/users'
import { ref, reactive, computed, onMounted } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { Project, Calendar, User } from '@api/_apiTypes'
import { apiGetProjects, apiResetTestProject, type ResetResponse } from '@api/project'
import { useAuthStore } from '@/stores/authStore'

import refreshProgress from '@/utilities/refreshProgress' // loader utilities

const useCalendarStore = defineStore('calendar', () => {
    const calendars = useLocalStorage('calendars', ref<Calendar[]>([]))
    const projects = useLocalStorage('projects', ref<Project[]>([]))
    const users = useLocalStorage('users', ref<User[]>([]))
    const activeUsers = useLocalStorage('activeUsers', ref<User[]>([]))
    const partnerUsers = useLocalStorage('partnerUsers', ref<User[]>([]))
    const calPoll = useLocalStorage('calPoll', ref<number>(Date.now()))
    const calendarDefault = useLocalStorage('calendarDefault', ref<number>(0))

    const authStore = useAuthStore()
    const { meId } = storeToRefs(authStore)

    const projectsVisible = computed(() => {
        return projects.value.filter((project) => {
            return project.visible
        })
    })

    const myCalendars = computed(() => {
        return calendars.value.filter((calendar) => {
            if (!calendar.authorised_webuser_id) return
            return calendar.authorised_webuser_id.includes(meId.value)
        })
    })

    async function refreshCalendar() {
        const calendarResponse = await getCalendars()

        if (!calendarResponse.items || !calendarResponse.items.length) return

        calendars.value = calendarResponse.items as Calendar[]
    }

    async function refreshProjects() {
        const projectResponse = await apiGetProjects()

        if (!projectResponse.items || !projectResponse.items.length) {
            console.warn('CONTENTKALENDER : No projects found, is this user bound to a calendar with valid projects?')
            return
        }
        projects.value = projectResponse.items as Project[]
    }

    async function refreshUsers() {
        const userResponse = await apiGetUsers()
        if (userResponse && userResponse.items?.length) {
            users.value = userResponse.items.map((user) => {
                return {
                    ...user,
                    name: `${user.firstName !== null ? user.firstName : ''} ${user.middleName !== null ? user.middleName + ' ' : ''}${user.lastName !== null ? user.lastName : ''}`,
                }
            }) as User[]

            activeUsers.value = users.value.filter((user) => {
                return user.active === true && (user.isCollaborationUser === false || user.isCollaborationUser === null)
            }) as User[]

            partnerUsers.value = users.value.filter((user) => {
                return user.active === true && user.isCollaborationUser === true
            }) as User[]
        }
    }

    async function resetProject() {
        const projectResetResponse: ResetResponse = await apiResetTestProject()
        if (projectResetResponse) {
            // dele projects from the store that have property test = true
            projects.value = projects.value.filter((project) => {
                return !project.test
            })
        }
    }

    // user functions
    async function refreshStore() {
        await refreshCalendar()
        await refreshProjects()
        await refreshUsers()
    }

    function resetStore() {
        projects.value = []
        calendars.value = []
        users.value = []
    }

    // REVIEW is this Legacy??
    // dialog handling
    const dialogShow = reactive({
        mode: 'create', // create | read | update | delete
        popup: false,
        id: 0,
    })

    const dialog = (mode: string, id: number) => {
        dialogShow.mode = mode
        dialogShow.id = id
        dialogShow.popup = true
    }

    function getUserById(id: number) {
        return users.value.find((user) => {
            return user.id === id
        })
    }

    function getProjectById(id: number) {
        return projects.value.find((project) => {
            return project.id === id
        })
    }
    // setting the primary fields

    const canMakeRequest = computed(() => {
        let _request: boolean = false
        myCalendars.value.forEach((cal) => {
            if (_request || !cal.receiveRequest) return
            _request = cal.receiveRequest
        })
        return _request
    })

    const computedPassiveCalendarIds = computed(() => {
        return calendars.value
            .filter((calendar) => {
                return calendar.passive_webuser_id?.includes(meId.value)
            })
            .map((cal) => {
                return cal.id
            })
    })

    onMounted(() => {
        const _refreshCalendar = refreshProgress.registerRefreshFunction('cal')
        _refreshCalendar('calendars', refreshCalendar)
        _refreshCalendar('projects', refreshProjects)
        _refreshCalendar('users', refreshUsers)
    })

    return {
        refreshCalendar,
        refreshProjects,
        refreshStore,
        refreshUsers,
        calPoll,
        resetProject,
        resetStore,
        dialogShow,
        dialog,
        calendars,
        calendarDefault,
        myCalendars,
        canMakeRequest,
        projects,
        projectsVisible,
        users,
        activeUsers,
        partnerUsers,
        getUserById,
        getProjectById,
        computedPassiveCalendarIds,
    }
})

export { useCalendarStore }
